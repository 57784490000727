import { toggleClass } from "./dom-helpers";

export default class ClassBurger {
  constructor(
    label,
    onClass = "open",
    callback = function () {
      return true;
    }
  ) {
    const dataToggles = document.querySelectorAll(
      '[data-classburger="' + label + '"]'
    );
    if (dataToggles.length > 0) {
      // Bind toggle trigger
      this.bindTrigger(dataToggles, label, onClass, callback);
    }
    // Add an else condition here to debug if element is
    // existing on current page
  }

  bindTrigger(nodes, label, onClass, callback) {
    const toggleTriggers = document.querySelectorAll(
      '[data-classburger-trigger="' + label + '"]'
    );
    if (toggleTriggers.length > 0) {
      // Bind click handler
      for (let i = 0; i < toggleTriggers.length; i++) {
        toggleTriggers[i].addEventListener("click", function (event) {
          event.preventDefault();
          event.stopPropagation();
          for (let j = 0; j < nodes.length; j++) {
            toggleClass(nodes[j], onClass);
            callback(nodes[j]);
          }
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(
        "Trigger matching togglable element value" + label + "does not exist"
      );
    }
  }
}
