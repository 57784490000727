import { addClass, removeClass } from "./dom-helpers";

export default class TabGroup {
  constructor(group) {
    this.triggers = Array.from(group.querySelectorAll("[data-tab-trigger]"));
    this.contents = Array.from(group.querySelectorAll("[data-tab-content]"));

    // If there is a hash link that matches the tab content, set that to current
    // otherwise, start at 0
    this.current = this.getCurrentFromHash() ? this.getCurrentFromHash() : 0;
    this.activeClass = "active";

    this.updateTabs();
    this.bindTriggers();
  }

  bindTriggers() {
    this.triggers.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        this.current = parseInt(trigger.getAttribute("data-tab-trigger"));
        this.updateTabs();
      });
    });
  }

  getCurrentFromHash() {
    let current = false;
    const hash = window.location.hash.substr(1);
    this.contents.forEach(function (tab) {
      // Get the tab-anchor from each tab, if that parameter exists, and match it against the hash
      if (tab.getAttribute("data-tab-anchor")) {
        if (
          tab.getAttribute("data-tab-anchor") === hash &&
          tab.getAttribute("data-tab-content")
        ) {
          current = parseInt(tab.getAttribute("data-tab-content"));
        }
      }
    });

    return current;
  }

  updateTabs() {
    this.triggers.forEach((trigger) => {
      removeClass(trigger, this.activeClass);
      if (parseInt(trigger.getAttribute("data-tab-trigger")) === this.current) {
        addClass(trigger, this.activeClass);
      }
    });

    this.contents.forEach((tab) => {
      removeClass(tab, this.activeClass);
      if (parseInt(tab.getAttribute("data-tab-content")) === this.current) {
        addClass(tab, this.activeClass);
      }
    });
  }
}
