import { hasClass, addClass, removeClass } from "./dom-helpers";

export default class FaqList {
  constructor(node) {
    // Get top level questions
    this.activeClass = "open";

    const allChildren = Array.from(node.querySelectorAll("li"));
    const questions = allChildren.filter(function (child) {
      return child.parentNode === node;
    });

    this.addNestedClass(questions);
    this.addToggleIndicators(questions);
    this.bindToggles(questions);
  }

  addNestedClass(questions) {
    questions.forEach(function (question) {
      if (question.querySelectorAll("ul ul ul")) {
        const nestedLists = Array.from(question.querySelectorAll("ul ul ul"));
        nestedLists.forEach(function (list) {
          addClass(list, "nested-list");
        });
      }
    });
  }

  addToggleIndicators(questions) {
    // Add toggle indicators to top level <li> questions, requires access to
    // <li> first child text element
    questions.forEach(function (question) {
      if (question.childNodes[1]) {
        const toggleIndicator = document.createElement("i");
        toggleIndicator.className = "toggle-indicator";

        question.insertBefore(toggleIndicator, question.childNodes[1]);
      }
    });
  }

  bindToggles(questions) {
    questions.forEach((question) => {
      question.addEventListener("click", () => {
        this.handleToggle(questions, question);
      });
    });
  }

  handleToggle(questions, question) {
    // If the question was already active, close it,
    // otherwise, close all questions and open this one.
    // Remove classes from all toggles
    if (hasClass(question, this.activeClass)) {
      removeClass(question, this.activeClass);
    } else {
      this.removeActive(questions);
      addClass(question, this.activeClass);
    }
  }

  removeActive(questions) {
    questions.forEach((question) => {
      removeClass(question, this.activeClass);
    });
  }
}
