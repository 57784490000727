import velocity from "velocity-animate";

export default class SmoothAnchors {
  constructor(el) {
    const anchors = el.querySelectorAll('a[href*="#"]');
    [...anchors].forEach((anchor) => {
      if (
        anchor.getAttribute("href").length > 1 &&
        window.location.pathname.replace(/^\//, "") ===
          anchor.pathname.replace(/^\//, "") &&
        window.location.hostname === anchor.hostname
      ) {
        anchor.addEventListener("click", (event) => {
          this.smoothScroll(el, anchor, event);
        });
      }
    });
  }

  smoothScroll(el, anchor, event) {
    const target = anchor.hash;
    const targetEl = el.querySelector(`[name=${target.substring(1)}]`);

    // Calculate difference between target element and current scroll
    const currentScroll = window.pageYOffset;

    const targetRect = targetEl.getBoundingClientRect();
    const rectTop = targetRect.top + document.body.scrollTop;

    const speed = 5;
    const distance = Math.abs(currentScroll - rectTop);

    if (targetEl) {
      event.preventDefault();
      velocity(targetEl, "scroll", {
        duration: distance / speed,
      });
    }
  }
}
