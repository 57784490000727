import { hasClass, addClass, removeClass } from "./dom-helpers";

export default class CssSlider {
  constructor(count, node) {
    this.min = 0;
    this.max = count - 1;
    this.node = node;
    this.element = this.node.querySelector("[data-element]");
    // Keep track of slides in current
    this.current = 0;

    this.initOrdinals();
    this.initPagers();
    this.updateOrdinals();
  }

  initOrdinals() {
    this.ordinals = Array.from(
      this.node.querySelectorAll("[data-pager-ordinal]")
    );
    this.ordinals.forEach((ordinal) => {
      ordinal.addEventListener("click", () => {
        this.current = parseInt(ordinal.getAttribute("data-pager-ordinal")) - 1;
        this.updatePosition();
        this.updateOrdinals();
      });
    });
  }

  initPagers() {
    const pagersLeft = this.node.querySelectorAll('[data-pager="left"]');
    const pagersRight = this.node.querySelectorAll('[data-pager="right"]');

    Array.from(pagersLeft).forEach((pager) => {
      pager.addEventListener("click", () => {
        this.pageLeft();
      });
    });

    Array.from(pagersRight).forEach((pager) => {
      pager.addEventListener("click", () => {
        this.pageRight();
      });
    });
  }

  pageLeft() {
    if (this.current > this.min) {
      this.current -= 1;
      this.updatePosition();
      this.updateOrdinals();
    } else {
      this.current = this.max;
      this.updatePosition();
      this.updateOrdinals();
    }
  }

  pageRight() {
    if (this.current < this.max) {
      this.current += 1;
      this.updatePosition();
      this.updateOrdinals();
    } else {
      this.current = this.min;
      this.updatePosition();
      this.updateOrdinals();
    }
  }

  updateOrdinals() {
    this.ordinals.forEach((ordinal) => {
      if (hasClass(ordinal, "active")) {
        removeClass(ordinal, "active");
      }
      if (
        ordinal.getAttribute("data-pager-ordinal") ===
        this.current + 1 + ""
      ) {
        addClass(ordinal, "active");
      }
    });
  }

  updatePosition() {
    this.element.style.transform = "translateX(-" + this.current * 100 + "vw)";
  }
}
