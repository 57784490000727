/* global ga */
// Import CIC classes, functions
import fluidVids from "./lib/fluidvids";
import { hasClass, removeClass, toggleClass } from "./dom-helpers";
import ClassBurger from "./class-burger";
import CssSlider from "./css-slider";
import TabGroup from "./tab-group";
import FaqList from "./faq-list";
import SmoothAnchors from "./smooth-anchors";

export default class RitaAllenTheme {
  init() {
    const allLinks = Array.from(document.getElementsByTagName("a"));

    const addGaClickEvent = function (link, category, label) {
      link.addEventListener("click", function () {
        ga("send", "event", category, "click", label);
      });
    };

    // Regex for external links from here: https://css-tricks.com/snippets/jquery/open-external-links-in-new-window/
    const local = new RegExp(`/${window.location.host}/`);
    // Regex for pdf downloads
    const pdf = /\.pdf$/;
    // Loop through all links
    allLinks.forEach(function (link) {
      const href = link.getAttribute("href");
      if (href && href.substring(0, 4) === "http" && !local.test(href)) {
        // Open External links in new windows
        // But adds a target="_blank" to external links. Also ignores any link not prepended with http (as mentioned here: http://stackoverflow.com/posts/2911176/revisions)
        link.setAttribute("target", "_blank");
        addGaClickEvent(link, "External Link", href);
      } else if (pdf.test(href)) {
        // PDF downloads
        addGaClickEvent(link, "PDF Download", href);
      }
    });

    // After the search overlay is open listen for escape key to be pressed
    const addEscListener = function (node, openClass) {
      document.addEventListener("keyup", function onEscHandler(event) {
        // If escape key is pressed close the overlay and remove the event listener
        if (event.keyCode === 27) {
          removeClass(node, openClass);
          // Remove no-scroll class from the body tag if it has one
          if (hasClass(document.getElementsByTagName("body")[0], "no-scroll")) {
            removeClass(document.getElementsByTagName("body")[0], "no-scroll");
          }
          document.removeEventListener("keyup", onEscHandler);
        }
      });
    };

    const addOutsideClickListener = function (node, openClass) {
      document.addEventListener("click", function onClickHandler(event) {
        // If a click event occurs outside the element, remove its open class
        if (event.target !== node) {
          removeClass(node, openClass);
          document.removeEventListener("click", onClickHandler);
        }
      });
    };

    function setTransitionFocus(event) {
      const target = event.target;

      if (hasClass(target, "open")) {
        const focusable = target.querySelectorAll("[href], input, button");
        focusable[0].focus();
      }
    }

    const navigation = new ClassBurger("navigation", "open", function (node) {
      if (hasClass(node, "open")) {
        node.addEventListener("transitionend", setTransitionFocus);
      } else {
        node.removeEventListener("transitionend", setTransitionFocus);
      }

      addEscListener(node, "open");
    });

    const search = new ClassBurger("search", "open", function (node) {
      if (hasClass(node, "open")) {
        node.addEventListener("transitionend", setTransitionFocus);
      } else {
        node.removeEventListener("transitionend", setTransitionFocus);
      }

      addEscListener(node, "open");
    });

    const newsletter = new ClassBurger("newsletter", "open", function (node) {
      const nameInput = node.getElementsByTagName("input")[0];
      nameInput.focus();
    });

    const tags = new ClassBurger("tags", "open", function (node) {
      addEscListener(node, "open");
      addOutsideClickListener(node, "open");
    });

    const caption = new ClassBurger("caption", "open");

    // Make an arbitrarily named class-burger when there are modals
    const modals = Array.from(document.querySelectorAll("[data-modal]"));
    modals.forEach(function (modal) {
      const classBurger = new ClassBurger(
        modal.getAttribute("data-classburger"),
        "open",
        function (node) {
          toggleClass(document.getElementsByTagName("body")[0], "no-scroll");
          addEscListener(node, "open");
        }
      );
    });

    // Instantiate slider JS for each slider on a page
    const sliders = Array.from(document.querySelectorAll("[data-slider]"));
    sliders.forEach(function (slider) {
      const cssSlider = new CssSlider(
        slider.querySelector("[data-element]").getAttribute("data-count"),
        slider
      );
    });

    // Instantiate tab JS for tabs on a page
    const tabs = Array.from(document.querySelectorAll("[data-tab-group]"));
    // For each tabgroup
    tabs.forEach(function (tabGroup) {
      const tabs = new TabGroup(tabGroup);
    });

    // Instantiate FAQ list/accordion JS for FAQ lists in RTE content.
    const faqs = Array.from(document.querySelectorAll("[data-faq-list]"));
    faqs.forEach(function (faq) {
      const faqList = new FaqList(faq);
    });

    // Run FluidVids library for responsive video resizing
    fluidVids(window, document);

    // Initialize anchors links
    const anchors = new SmoothAnchors(document.querySelector("body"));
  }
}
