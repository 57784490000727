export default class TargetLinks {
  constructor() {
    this.checkLinks();
  }

  get links() {
    return document.links;
  }

  checkLinks = () => {
    [...this.links].forEach((link) => {
      const url = link.getAttribute("href");
      if (!this.shouldLinkOut(url)) return;
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });
  };

  checkDomain(url) {
    if (url.indexOf("//") === 0) {
      url = window.location.protocol + url;
    }
    return url
      .toLowerCase()
      .replace(/([a-z])?:\/\//, "$1")
      .split("/")[0];
  }

  isExternal(url) {
    return (
      (url.indexOf(":") > -1 || url.indexOf("//") > -1) &&
      this.checkDomain(window.location.href) !== this.checkDomain(url)
    );
  }

  shouldLinkOut(url) {
    if (url.match(/\.pdf$/i)) return true;
    if (this.isExternal(url)) return true;
    return false;
  }
}
